import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import * as JStorageActions from '../../Actions/JStorage';
import {Button, message, Input, Result, Table, Pagination} from 'antd';
import {PROVIDER, PROVIDER_DISPLAY} from '../../Constants';
import {
  PageTitle,
  SectionTitle,
  FieldRow,
  FieldLabel,
  FieldValue,
  SeperatorLine,
} from '../../Components/Widgets';
import moment from 'moment';

function validDateString(str) {
  let regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  return regex.test(str) && moment(str, 'YYYY-MM-DD').isValid();
}

function getQueryFromDate(dateFrom, dateTo, isDateToIncluded = true) {
  // generate dates
  let diffDayCount =
    moment(dateTo, 'YYYY-MM-DD').diff(moment(dateFrom, 'YYYY-MM-DD'), 'days') +
    (isDateToIncluded ? 1 : 0);

  let dates = [...new Array(diffDayCount)]
    .map((i, idx) =>
      moment(dateFrom, 'YYYY-MM-DD').startOf('day').add(idx, 'days'),
    )
    .map((md) => ({
      year: md.year(),
      month: md.month() + 1,
      day: md.date(),
    }));

  // aggregate dates for the same month / year
  let groupYears = dates.reduce((acc, curr) => {
    if (acc[curr.year]) {
      if (acc[curr.year][curr.month]) {
        acc[curr.year][curr.month].push(curr.day);
      } else {
        acc[curr.year][curr.month] = [curr.day];
      }
    } else {
      acc[curr.year] = {
        [curr.month]: [curr.day],
      };
    }

    return acc;
  }, {});

  // convert to jstorage query object
  let queryItems = [];
  for (let y in groupYears) {
    for (let m in groupYears[y]) {
      let queryItem = {};

      queryItem = {
        year: Number(y),
        month: Number(m),
        day: {
          $in: groupYears[y][m],
        },
      };
      queryItems.push(queryItem);
    }
  }

  return {
    $or: queryItems,
  };
}

const _columns = [
  {
    title: 'SKU',
    key: 'sku',
    dataIndex: 'sku',
  },
  {
    title: '銷售量',
    key: 'qty',
    dataIndex: 'qty',
  },
];

const ORDER_PROVIDERS = [
  PROVIDER.shopee,
  PROVIDER.yahoo,
  PROVIDER.yahoomall,
  PROVIDER.rakuten,
  PROVIDER.momomall,
  // PROVIDER.momocenter // we don't have momocenter orders in database
];

function AdminHomePage(props) {
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = React.useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [bestSaleItemsByProvider, setBestSaleItemsByProvider] = React.useState({
    [PROVIDER.shopee]: [],
    [PROVIDER.yahoo]: [],
    [PROVIDER.yahoomall]: [],
    [PROVIDER.rakuten]: [],
    [PROVIDER.momomall]: [],
    //    [PROVIDER.momocenter]: [], // we don't have momocenter orders in database
  });
  const [filters, setFilters] = React.useState({
    created_from: '',
    created_to: '',
  });
  const [activeFilters, setActiveFilters] = React.useState({
    created_from: '',
    created_to: '',
  });

  React.useEffect(() => {
    let current = moment();
    let currentYear = current.year();
    let currentMonth = `0${current.month() + 1}`.slice(-2);
    let firstDateOfMonth = `0${current.startOf('month').date()}`.slice(-2);
    let lastDateOfMonth = `0${current.endOf('month').date()}`.slice(-2);

    let from = `${currentYear}-${currentMonth}-${firstDateOfMonth}`;
    let to = `${currentYear}-${currentMonth}-${lastDateOfMonth}`;
    setFilters({
      created_from: from,
      created_to: to,
    });
    setActiveFilters({
      created_from: from,
      created_to: to,
    });
  }, []);

  const fetchOrders = async (filters) => {
    try {
      // get all available day of month
      let query = getQueryFromDate(filters.created_from, filters.created_to);

      let data = await await JStorageActions.fetchDocuments(
        'order',
        query,
        [],
        null, // paging
        {
          year: 1,
          month: 1,
          day: 1,
          total: 1,
          erp_id: 1,
          order_id: 1,
          provider: 1,
          items: 1,
        },
      );

      let dataGroupByProvider = data.reduce((acc, curr) => {
        if (acc[curr.provider]) {
          acc[curr.provider].push(curr);
        } else {
          acc[curr.provider] = [curr];
        }
        return acc;
      }, {});

      let bestSalesArrayByProvider = ORDER_PROVIDERS.map(
        (p) => dataGroupByProvider[p] || [],
      ).map((data, idx) => {
        let productSales = {};
        for (let d of data) {
          for (let i of d.items) {
            productSales[i.sku] = productSales[i.sku]
              ? productSales[i.sku] + Number(i.qty)
              : Number(i.qty);
          }
        }
        return productSales;
      }); // [{RD200HG: 1, RD240HS: 2, R2111111031: 1}, {}, ...]

      let bestSalesObjectByProvider = ORDER_PROVIDERS.reduce(
        (acc, providerKey, idx) => {
          let salesRecord = Object.keys(bestSalesArrayByProvider[idx]).reduce(
            (itemsOfProvider, itemSku, _idx) => {
              let qty = bestSalesArrayByProvider[idx][itemSku];
              itemsOfProvider.push({sku: itemSku, qty});
              return itemsOfProvider;
            },
            [],
          );
          acc[providerKey] = salesRecord
            .slice()
            .sort((a, b) => b.qty - a.qty)
            .slice(0, 10); // only show top 10 sales
          return acc;
        },
        {},
      );

      setBestSaleItemsByProvider(bestSalesObjectByProvider);
    } catch (err) {
      console.log('err', err);
      message.error('無法取得資料');
    }
  };

  const validateInputQuery = function (data) {
    try {
      if (!data.created_from || !data.created_to) {
        throw '日期為必填欄位！';
      }
      if (data.created_from > data.created_to) {
        throw '日期區間有誤！';
      }
      if (
        !validDateString(data.created_from) ||
        !validDateString(data.created_to)
      ) {
        throw '日期格式填寫有誤！';
      }
      if (
        moment(data.created_to, 'YYYY-MM-DD').diff(
          moment(data.created_from, 'YYYY-MM-DD'),
          'days',
        ) > 184
      ) {
        throw '最多顯示六個月的資料';
      }
    } catch (err) {
      message.error(err);
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    const _fetchData = async () => {
      setBtnLoading(true);
      try {
        await fetchOrders(activeFilters);
      } catch (err) {
      } finally {
        setBtnLoading(false);
      }
    };
    if (activeFilters.created_from && activeFilters.created_to) {
      _fetchData();
    }
  }, [activeFilters]);

  const onSearch = async () => {
    let isValid = validateInputQuery(filters);
    if (!isValid) {
      return;
    }
    setActiveFilters(filters);
  };

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
        <PageTitle>首頁</PageTitle>
      </div>

      <SectionTitle>各平台熱賣商品一覽</SectionTitle>

      <div className="filters">
        <div className="inputs">
          <div className="item">
            <label>訂單成立起始日</label>
            <Input
              placeholder={'YYYY-MM-DD'}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  created_from: e.target.value,
                })
              }
              value={filters.created_from}
            />
          </div>
          <div className="item">
            <label>訂單成立結束日</label>
            <Input
              placeholder={'YYYY-MM-DD'}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  created_to: e.target.value,
                })
              }
              value={filters.created_to}
            />
          </div>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button
          loading={btnLoading}
          disabled={loading || btnLoading}
          onClick={onSearch}>
          搜尋
        </Button>
      </div>

      <div className="result">
        {(!!bestSaleItemsByProvider || loading) &&
          ORDER_PROVIDERS.map((p, idx) => (
            <div key={p} style={{marginBottom: 20}}>
              <h3 style={{marginBottom: 10}}>{PROVIDER_DISPLAY[p]}</h3>
              <Table
                key={p}
                dataSource={bestSaleItemsByProvider[p]}
                rowKey="id"
                pagination={false}
                columns={_columns}
                loading={loading}
              />
            </div>
          ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & .result {
    margin-top: 50px;
  }
  & > .filters {
    padding: 20px 0px;
    & > .note {
      font-size: 14px;
      color: grey;
      text-align: right;
    }
    & > .inputs {
      & > .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & label {
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }
  }
`;

export default AdminHomePage;
